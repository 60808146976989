import qs from 'qs'
export function createAuthGuard(router) {
  router.beforeEach((to, from, next) => {
    const { search, origin } = location
    const { redirect, code }: any = qs.parse(search.slice(1))
    if (redirect && code) {
      // 代表从第三方授权回来,处理返回的链接
      location.href = `${origin}?code=${code}#${redirect?.replaceAll('|', '&')}`
    } else {
      next()
    }
  })
}
