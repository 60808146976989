import type { App } from 'vue'
import { Card } from 'framework/components/Card'
import { BasicModal } from 'framework/components/Modal'
import { Empty, TooltipInfo } from 'framework/components/Basic/index'
import { createFromIconfontCN } from '@ant-design/icons-vue'

const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_3498561_nfd457dj9xs.js',
})

export function registerGlobComponent(app: App) {
  app.component('SCard', Card)
  app.component('SModal', BasicModal)
  app.component('IconFont', IconFont)
  app.component('SEmpty', Empty)
  app.component('STooltipInfo', TooltipInfo)
}
